import { gql, useMutation, useQuery } from '@apollo/client';
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import toast from 'react-hot-toast';

import { ApplyRecurrenceToCartDocument } from '@generated/graphql';
import { useCart } from '@hooks';
import { APP_STORAGE_KEYS, Persistence } from '@utils';

export const GET_RECURRENCE_CONFIG = gql`
  query GetRecurrenceConfig {
    recurrenceConfig {
      enabled
      visible
      payment_id
      shipping_id
      cupom_code
      minimun_value
      cupom_discount
      select {
        label
        value
      }
    }
  }
`;

export interface iRecurrenceConfig {
  enabled: '1' | '0';
  payment_id: string;
  shipping_id: string;
  cupom_code: string;
  minimun_value: string;
  cupom_discount: number;
  visible: '1' | '0';
  select: {
    label: string;
    value: string;
  }[];
}

export interface iRecurrenceContext {
  recurrenceConfig: iRecurrenceConfig;
  handleSignatureToggle(
    recurrenceValue: boolean,
    cartIdParam?: string,
    shouldFetchCart?: boolean
  ): Promise<void>;
  signatureInterval: string | null;
  handleSetSignatureInterval(value: string): void;
}

export const RecurrenceContext = createContext<iRecurrenceContext>(
  {} as iRecurrenceContext
);

export const RecurrenceProvider = memo(
  ({ children }: { children: ReactNode }) => {
    const { cartId, fetchCart } = useCart();
    const [recurrenceConfig, setRecurrenceConfig] = useState<iRecurrenceConfig>(
      {} as iRecurrenceConfig
    );
    const [signatureInterval, setSignatureInterval] = useState<string | null>(
      Persistence.getItem(APP_STORAGE_KEYS.signatureInterval)
    );

    const [handleApplyRecurrence] = useMutation(ApplyRecurrenceToCartDocument);
    const { data: foundRecurrenceConfigs } = useQuery(GET_RECURRENCE_CONFIG);

    const handleSetSignatureInterval = useCallback((value: string) => {
      setSignatureInterval(value);
      Persistence.setItem(APP_STORAGE_KEYS.signatureInterval, value);
    }, []);

    const handleSignatureToggle = useCallback(
      async (
        recurrenceValue: boolean,
        createdCartId?: string,
        shouldFetchCart?: boolean
      ) => {
        try {
          await handleApplyRecurrence({
            variables: {
              cartId: createdCartId || cartId,
              value: recurrenceValue
            },
            onCompleted: async () => {
              if (shouldFetchCart) {
                await fetchCart();
              }
            }
          });
          return;
        } catch (err) {
          // prevent showing toast when user cart or session is expired
          if (
            !err.message.includes(
              'O usuário atual não pode realizar operações no carrinho'
            )
          ) {
            toast.error(err.message);
            return;
          }
        }
      },
      [handleApplyRecurrence, cartId, fetchCart]
    );

    useEffect(() => {
      if (foundRecurrenceConfigs) {
        const config =
          foundRecurrenceConfigs.recurrenceConfig as iRecurrenceConfig;

        Persistence.setItem(APP_STORAGE_KEYS.recurrenceConfig, config);
        setRecurrenceConfig(config);
      }
    }, [foundRecurrenceConfigs]);

    useEffect(() => {
      if (!signatureInterval && recurrenceConfig?.select) {
        const selectedInterval = recurrenceConfig?.select[0].value;
        handleSetSignatureInterval(selectedInterval);
      }
    }, [recurrenceConfig?.select]);

    return (
      <RecurrenceContext.Provider
        value={{
          handleSignatureToggle,
          recurrenceConfig,
          signatureInterval,
          handleSetSignatureInterval
        }}
      >
        {children}
      </RecurrenceContext.Provider>
    );
  }
);

RecurrenceProvider.displayName = 'RecurrenceProvider';
