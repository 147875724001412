import dynamic from 'next/dynamic';
import { useEffect, useMemo, useRef } from 'react';

import { useCheckout } from 'src/hooks/useCheckout';
import { BoletoBancario } from './BoletoBancario';
import { Dinheiro } from './Dinheiro';
import { Deuna } from './Deuna';
import { PaymentMethodItem } from './PaymentMethodItem';
import { Pix } from './Pix';

import BoletoSvg from '@assets/icons/boleto-checkout.svg';
import CreditCardSvg from '@assets/icons/credit-card-checkout.svg';
import MoneySvg from '@assets/icons/money.svg';
import PixSvg from '@assets/icons/pix.svg';
import { Alert, Button } from '@components/common';
import { Accordion } from '@components/common/Accordion';
import { useCart, useGTMDataLayer } from '@hooks';
import { configuredVariant } from '@utils';
import { isEmpty } from 'lodash';
import {
  getBreadcrumbCategory,
  isProductConfigurable,
  useCustomAttributes
} from 'src/hooks/useProductFullDetail';
import DollarSign from '@assets/icons/sign-dollar.svg';
import { PaymentMethodType } from '@contexts/checkout';

const WireCard = dynamic(() => import('./WireCard').then(mod => mod.WireCard), {
  ssr: false
});

export const paymentMethodsMap = {
  backoffice_cc: {
    icon: MoneySvg,
    content: Dinheiro
  },
  checkmo: {
    icon: MoneySvg,
    content: Dinheiro
  },
  pagbank_paymentmagento_boleto: {
    icon: BoletoSvg,
    content: BoletoBancario
  },
  // pagarme_creditcard: {
  //   icon: CreditCardSvg,
  //   content: () => null
  // },
  pagbank_paymentmagento_pix: {
    icon: PixSvg,
    content: Pix
  },
  pagbank_paymentmagento_cc: {
    icon: CreditCardSvg,
    content: WireCard
  },
  deuna_paymentlink: {
    icon: CreditCardSvg,
    content: Deuna
  }
};

interface iPaymentMethods {
  onOpen(): void;
  selectedMethod: Pick<PaymentMethodType, 'additional_info' | 'code' | 'title'>;
  setSelectedMethod(
    paymentMethod: Pick<PaymentMethodType, 'additional_info' | 'code' | 'title'>
  ): void;
  isDisabled?: boolean;
  isLoadingCheckout?: boolean;
  handlePlaceOrder?(): Promise<void>;
}

export const PaymentMethods = ({
  onOpen,
  selectedMethod,
  setSelectedMethod,
  isDisabled = false,
  handlePlaceOrder
}: iPaymentMethods) => {
  const { isSignature, cart } = useCart();
  const {
    paymentMethodList,
    paymentMethodAccordionRef,
    paymentMethodCodeHandler,
    paymentMethodCodeSelected,
    refetchPaymentMethods
  } = useCheckout();
  const pageViewControl = useRef(false);
  const selectedPaymentMethodControl = useRef('');
  const { pushToDataLayer } = useGTMDataLayer();

  const paymentMethodItemRender = useMemo(() => {
    const foundItem = paymentMethodsMap[selectedMethod.code];

    if (foundItem) {
      const Component = foundItem.content;
      return (
        <Component
          expiration={selectedMethod?.additional_info?.expiration}
          handlePlaceOrder={handlePlaceOrder}
        />
      );
    }

    return <></>;
  }, [
    handlePlaceOrder,
    selectedMethod?.additional_info?.expiration,
    selectedMethod.code
  ]);

  const {
    getProductCustomAttributes,
    loading: isCustomAttributesLoading,
    metadata: customAttributesMetadata
  } = useCustomAttributes();

  const cartDetails = useMemo(() => {
    return isCustomAttributesLoading ||
      isEmpty(customAttributesMetadata) ||
      !cart
      ? null
      : {
          ...cart,
          items: cart?.items?.map(item => {
            const configured_variant =
              configuredVariant(item?.configurable_options, item?.product) ||
              item?.product;

            const custom_attributes =
              getProductCustomAttributes(configured_variant);

            const isConfigurable =
              item?.product.__typename && isProductConfigurable(item?.product);

            return {
              ...item,
              product: {
                parent_sku: isConfigurable ? item?.product?.sku : null,
                is_configurable: isConfigurable,
                ...item?.product,
                ...configured_variant,
                category: getBreadcrumbCategory(item?.product?.categories),
                ...custom_attributes
              }
            };
          })
        };
  }, [
    cart,
    customAttributesMetadata,
    getProductCustomAttributes,
    isCustomAttributesLoading
  ]);

  useEffect(() => {
    refetchPaymentMethods();
  }, [refetchPaymentMethods]);

  /**
   * This effect selects the first payment method when the component is
   * loaded and there is only one item available
   */
  useEffect(() => {
    if (!selectedMethod?.code && paymentMethodList?.length > 0) {
      const method = paymentMethodList[0];
      setSelectedMethod(method);
      paymentMethodCodeHandler(method?.code as string);
    }
  }, [
    isDisabled,
    paymentMethodCodeHandler,
    paymentMethodList,
    selectedMethod?.code,
    setSelectedMethod
  ]);

  useEffect(() => {
    if (!pageViewControl.current) {
      pushToDataLayer('checkoutPagePayment', {});
      pageViewControl.current = true;
    }
  }, [pageViewControl, pushToDataLayer]);

  useEffect(() => {
    if (
      selectedMethod &&
      paymentMethodCodeSelected &&
      !isEmpty(paymentMethodCodeSelected) &&
      (!selectedPaymentMethodControl.current ||
        selectedPaymentMethodControl.current !== paymentMethodCodeSelected)
    ) {
      pushToDataLayer('checkoutPagePayment', {
        step: 4,
        pageType: 'checkoutPagePayment',
        option: 'Pagamento selecionado',
        additional_info: selectedMethod,
        ...cartDetails
      });

      selectedPaymentMethodControl.current = paymentMethodCodeSelected;
    }
  }, [paymentMethodCodeSelected, pushToDataLayer, selectedMethod, cartDetails]);

  useEffect(() => {
    if (paymentMethodAccordionRef?.current) {
      if (isDisabled) {
        paymentMethodAccordionRef.current.close();
      } else {
        paymentMethodAccordionRef.current.open();
      }
    }
  }, [isDisabled, paymentMethodAccordionRef]);

  return (
    <>
      <div className="hidden lg:flex">
        <Accordion
          id="payment-method-accordion"
          label={selectedMethod.title ? 'Pagamento' : 'Escolha como pagar'}
          value={selectedMethod.title}
          ref={paymentMethodAccordionRef}
          disabled={isDisabled}
          canBeEdited={selectedMethod.title && !isDisabled}
          customClickHandler={isDisabled ? () => {} : undefined}
          customIcon={
            <DollarSign
              className={
                selectedMethod?.title
                  ? 'text-primary-medium'
                  : 'text-gray-medium'
              }
            />
          }
          onOpen={onOpen}
        >
          {isSignature && (
            <Alert
              text="Para compra programada só é aceito o pagamento por cartão de crédito à vista."
              highlight="Compra programada"
            />
          )}
          <div className="pt-0 lg:pt-4 p-4 w-full">
            <div className="w-full flex gap-2">
              {paymentMethodList.map((item, index) => (
                <PaymentMethodItem
                  key={index}
                  value={item.code}
                  label={item.title}
                  inputId={item.code}
                  labelDiscount={item.label_discount}
                  name="payment-methods"
                  currentValue={selectedMethod?.code}
                  onChange={val => {
                    setSelectedMethod(item);
                    paymentMethodCodeHandler(val as string);
                  }}
                  icon={paymentMethodsMap[item.code]?.icon}
                >
                  <div>{paymentMethodItemRender}</div>
                </PaymentMethodItem>
              ))}
            </div>

            {!selectedMethod?.code && (
              <div className="block mt-4 lg:hidden">
                <Button className="w-full" disabled>
                  Continuar
                </Button>
              </div>
            )}
            <div>{paymentMethodItemRender}</div>
          </div>
        </Accordion>
      </div>

      <div className="flex flex-col lg:hidden">
        <div className="flex justify-start items-center w-fit-content p-2">
          <DollarSign
            className={
              selectedMethod?.title ? 'text-primary-medium' : 'text-gray-medium'
            }
          />
          <div className="ml-2">
            <span
              className={
                'font-sans font-bold normal text-[18px] text-gray-dark'
              }
            >
              Escolha como pagar
            </span>
          </div>
        </div>
        {isSignature && (
          <Alert
            text="Para compra programada só é aceito o pagamento por cartão de crédito à vista."
            highlight="Compra programada"
          />
        )}
        <div className="pt-0 lg:pt-4 p-2 w-full">
          <div className="w-full flex gap-2 overflow-x-auto">
            {paymentMethodList.map((item, index) => (
              <PaymentMethodItem
                key={index}
                value={item.code}
                label={item.title}
                inputId={item.code}
                labelDiscount={item.label_discount}
                name="payment-methods"
                currentValue={selectedMethod?.code}
                onChange={val => {
                  setSelectedMethod(item);
                  paymentMethodCodeHandler(val as string);
                }}
                icon={paymentMethodsMap[item.code]?.icon}
              >
                <div>{paymentMethodItemRender}</div>
              </PaymentMethodItem>
            ))}
          </div>

          <div>{paymentMethodItemRender}</div>
        </div>
      </div>
    </>
  );
};
