import classNames from 'classnames';
import Link from 'next/link';
import {
  Children,
  ReactNode,
  cloneElement,
  isValidElement,
  useMemo
} from 'react';

import { useBreadcrumbs } from 'src/hooks/useBreadcrumbs';

interface BreadcrumbsProps {
  children: ReactNode;
  className?: string;
}

export const Breadcrumbs = ({ children, className }: BreadcrumbsProps) => {
  return (
    <nav className={className}>
      <ol className="flex flex-wrap items-center -mb-1">
        {Children.map(children, (child, i) => {
          return (
            isValidElement(child) &&
            cloneElement(child as any, {
              divider: i !== Children.count(children) - 1
            })
          );
        })}
      </ol>
    </nav>
  );
};

interface BreadcrumbsItemProps {
  href?: string;
  children: ReactNode;
  divider?: boolean;
  onClick?: () => void;
}

export const BreadcrumbsItem = ({
  href,
  children,
  divider,
  onClick
}: BreadcrumbsItemProps) => {
  return href ? (
    <li
      className={classNames(
        'flex items-center after:mx-2 after:inline-block text-gray-darkest lg:text-gray-dark mb-1',
        { "after:content-['>']": divider }
      )}
    >
      <Link
        href={href}
        {...(onClick && { onClick })}
        prefetch={false}
        className="underline text-xs"
      >
        {children}
      </Link>
    </li>
  ) : (
    <li className="flex mb-1">
      <span className="text-gray-darkest text-xs lg:text-gray-dark">
        {children}
      </span>
    </li>
  );
};

/**
 * Breadcrumbs! Generates a sorted display of category links.
 *
 * @param {String} props.categoryId the id of the category for which to generate breadcrumbs
 * @param {String} props.productName the name of the product we're currently on, if any.
 */
interface BreadcrumbsCategoryProps {
  categoryId: number;
  productName?: string;
  className?: string;
}
export const BreadcrumbsCategory = ({
  categoryId,
  productName,
  className
}: BreadcrumbsCategoryProps) => {
  const talonProps = useBreadcrumbs({ categoryId });

  const { normalizedData } = talonProps;

  const links = useMemo(() => {
    return normalizedData.map(({ text, path }) => {
      return (
        <BreadcrumbsItem key={text} href={path}>
          {text}
        </BreadcrumbsItem>
      );
    });
  }, [normalizedData]);

  const currentProductNode = productName ? (
    <BreadcrumbsItem>{productName}</BreadcrumbsItem>
  ) : (
    []
  );

  return (
    <Breadcrumbs className={className}>
      <BreadcrumbsItem href="/">Página inicial</BreadcrumbsItem>
      {links}
      {currentProductNode}
    </Breadcrumbs>
  );
};
