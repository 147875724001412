import { Select } from '@components/common';
import { Checkbox } from '@components/common/Checkbox';
import { useGetCustomerAddressesQuery } from '@generated/graphql';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useCheckout } from 'src/hooks/useCheckout';
import { NewBillingAddressForm } from './Form';
import { OrderSummary } from '@components/common/OrderSummary';
import LoadingIndicator from '@components/common/LoadingIndicator';

export const BillingAddressForm = () => {
  const { data, refetch } = useGetCustomerAddressesQuery();
  const {
    isLoadingCheckout,
    selectedAddress,
    customerShippingUsed,
    selectedAddressHandler,
    customerShippingHandler,
    newBillingAddressFormValues
  } = useCheckout();

  const addressesListMemo = useMemo(() => {
    return data?.customer.addresses || [];
  }, [data]);

  const addressOptions = useMemo(() => {
    const list = addressesListMemo.map(item => ({
      value: item.id,
      label: `${item.firstname} - ${item.street[0]} ${item.street[1]}, ${item.city}`
    }));

    if (newBillingAddressFormValues) {
      const { firstname, street, number, city } = newBillingAddressFormValues;
      const option = {
        value: -1,
        label: `${firstname} - ${street} ${number}, ${city}`
      };
      selectedAddressHandler(option);
      return [...list, option, { value: 0, label: 'Novo endereço' }];
    }

    return [...list, { value: -1, label: 'Novo endereço' }];
  }, [addressesListMemo, newBillingAddressFormValues, selectedAddressHandler]);

  return (
    <div className="w-full">
      <div className="flex items-center py-2 my-2">
        <div className="mr-2">
          <Checkbox
            id="default_shipping_used"
            name="default_shipping_used"
            checked={customerShippingUsed}
            onChange={val => {
              customerShippingHandler(val);
            }}
          />
        </div>
        <div>
          <label
            htmlFor="default_shipping_used"
            className="normal font-normal text-sm text-gray-dark"
          >
            Endereço de cobrança é o mesmo de entrega
          </label>
        </div>
      </div>
      <div>
        {!customerShippingUsed && (
          <div className="w-full">
            <Select
              name="billing_address"
              options={addressOptions}
              value={selectedAddress.value !== 0 ? selectedAddress : null}
              onChange={value => selectedAddressHandler(value as any)}
              noOptionsMessage={() => 'Selecione um novo endereço'}
              placeholder="Selecione um novo endereço"
            />
          </div>
        )}
      </div>
      <div className="w-full">
        {selectedAddress.value === -1 &&
          !customerShippingUsed &&
          newBillingAddressFormValues === null && (
            <NewBillingAddressForm
              onFinish={() => {
                refetch();
              }}
            />
          )}
      </div>

      <div className="lg:hidden mb-10">
        <OrderSummary />
      </div>

      {isLoadingCheckout && (
        <div className="mb-2">
          <LoadingIndicator />
        </div>
      )}

      <div
        className={classNames(
          'w-full flex justify-end',
          (!customerShippingUsed && selectedAddress.value === 0) ||
            (!customerShippingUsed && selectedAddress.value === -1)
            ? 'hidden'
            : ''
        )}
      >
        <button
          type="submit"
          className={`w-full lg:w-1/2 lg:mt-2 group hover:outline-none focus:border-transparent focus:ring-0 relative flex justify-center hover:bg-seconday-dark focus:outline-none px-6 py-4 items-center justify-center uppercase font-bold text-sm rounded flex flex-row bg-primary-medium text-white hover:bg-primary-dark disabled:bg-secondary-light`}
        >
          Finalizar compra
        </button>
      </div>

      <div
        className={classNames(
          'w-full flex justify-end',
          !customerShippingUsed &&
            newBillingAddressFormValues !== null &&
            selectedAddress.value === -1
            ? ''
            : 'hidden'
        )}
      >
        <button
          type="submit"
          className="mt-6 group border-2 border-secondary-medium hover:outline-none focus:border-transparent focus:ring-0 relative w-full flex hover:bg-seconday-dark focus:outline-none px-6 py-4 items-center justify-center uppercase font-bold text-sm rounded flex flex-row bg-secondary-medium text-white hover:bg-secondary-dark disabled:bg-secondary-light"
        >
          Continuar
        </button>
      </div>
    </div>
  );
};
