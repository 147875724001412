import { ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import {
  ApolloNextAppProvider,
  SSRMultipartLink,
  ApolloClient,
  InMemoryCache
} from '@apollo/experimental-nextjs-app-support';
import { APP_STORAGE_KEYS, Persistence } from '@utils';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions, message, locations, path }) => {
      const cartId = Persistence.getItem(APP_STORAGE_KEYS.cartId);
      const token = Persistence.getItem(APP_STORAGE_KEYS.signinToken);

      if (extensions?.category === 'graphql-authorization' && cartId && token) {
        Persistence.removeItem(APP_STORAGE_KEYS.cartId);
        Persistence.removeItem(APP_STORAGE_KEYS.signinToken);
        history.go(0);
      }
      if (
        process.env.NODE_ENV !== 'production' &&
        typeof window !== 'undefined'
      ) {
        window.console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }
    });
  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from storage if it exists.
  const token = Persistence.getItem(APP_STORAGE_KEYS.signinToken);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

// Create a new store link to include store codes and currency in the request
const storeLink = setContext((_, { headers }) => {
  const storeCurrency =
    Persistence.getItem(APP_STORAGE_KEYS.storeViewCurrency) || null;
  const storeCodePersisted = Persistence.getItem(
    APP_STORAGE_KEYS.storeViewCode
  );

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      store: storeCodePersisted || 'default',
      ...headers,
      ...(storeCurrency && { 'Content-Currency': storeCurrency })
    }
  };
});

function makeClient() {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_MAGENTO_BACKEND_BASE_URL,
    credentials: 'same-origin'
  });

  const ssrHttpLink = ApolloLink.from([
    new SSRMultipartLink({
      /**
       * Whether to strip fragments with `@defer` directives
       * from queries before sending them to the server.
       *
       * Defaults to `true`.
       *
       * Can be overwritten by adding a label starting
       * with either `"SsrDontStrip"` or `"SsrStrip"` to the
       * directive.
       */
      stripDefer: true
      /**
       * The maximum delay in milliseconds
       * from receiving the first response
       * until the accumulated data will be flushed
       * and the connection will be closed.
       *
       * Defaults to `0`.
       */
      // cutoffDelay: 100
    }),
    httpLink
  ]);

  const netLink = typeof window === 'undefined' ? ssrHttpLink : httpLink;

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, authLink, storeLink, netLink])
  });
}

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
