import { useCart, useRecurrence, useZipcode } from '@hooks';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import ArrowPoingRight from '@assets/icons/arrow-point-right.svg';
import CircleCheckedSVG from '@assets/icons/circle-checked.svg';
import TrashIcon from '@assets/icons/trash.svg';
import { OrderProducts } from '@components/pages/Checkout/OrderProducts';
import { selectedShippingMethodAtom } from '@store/cart';
import { formatPostcode, priceFormat } from '@utils';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { SignatureBlock } from '@components/pages/Checkout/SignatureBlock';

type OrderSummaryProps = {
  isCartSummary?: boolean;
};

export const OrderSummary = ({ isCartSummary }: OrderSummaryProps) => {
  const {
    cart,
    fetchCart,
    isSignature,
    loading,
    handleAddCouponToCart,
    handleRemoveCouponFromCart
  } = useCart();
  const {
    recurrenceConfig: { enabled }
  } = useRecurrence();

  const { zipcode } = useZipcode();
  const { register, handleSubmit } = useForm();
  const selectedShippingMethod = useAtomValue(selectedShippingMethodAtom);

  const handleCoupom = useCallback(
    ({ couponCode }: { couponCode: string }) => {
      if (couponCode) handleAddCouponToCart(couponCode);
    },
    [handleAddCouponToCart]
  );

  const subtotalPrice = useMemo(() => {
    return (
      priceFormat({
        value: cart?.prices?.subtotal_excluding_tax?.value || 0
      }) || ''
    );
  }, [cart]);

  const shippingPrice = useMemo(() => {
    if (!isEmpty(selectedShippingMethod))
      return selectedShippingMethod?.amount?.value;

    return false;
  }, [selectedShippingMethod]);

  const formattedShippingPrice = useMemo(() => {
    if (shippingPrice)
      return priceFormat({
        value: Number(shippingPrice)
      });

    return false;
  }, [shippingPrice]);

  const totalPrice = useMemo(() => {
    return priceFormat({ value: cart?.prices?.grand_total?.value || 0 }) || '';
  }, [cart]);

  const postcodeOnCart = useMemo(() => {
    const postcode = cart?.shipping_addresses?.[0]?.postcode;

    if (postcode) {
      return formatPostcode(postcode);
    }

    return formatPostcode(zipcode);
  }, [cart, zipcode]);

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  return (
    <>
      {isSignature && enabled != '0' && !isCartSummary && (
        <SignatureBlock className="block lg:hidden" />
      )}

      <div
        className={`border border-gray-light rounded-md flex flex-col w-full px-2 py-4 ${!isCartSummary ? 'lg:ml-0' : 'lg:ml-4'}`}
      >
        <p className="ml-2 text-sm text-gray-darkest font-bold">
          Resumo da {isSignature ? ' compra programada' : 'compra'}
        </p>
        <span className="ml-2 mb-4 text-sm text-gray-darkest font-gotham">
          Enviar para o CEP <b>{postcodeOnCart}</b>
        </span>

        {!isCartSummary && (
          <OrderProducts productsOnCart={cart?.items ? cart?.items : []} />
        )}

        <div
          className={classNames(
            'border rounded-md border-gray-light w-full mt-2 lg:mt-0 lg:w-full h-fit'
          )}
        >
          <div className="p-4 text-gray-medium">
            <p className="flex justify-between items-center w-full font-gotham text-sm text-gray-dark">
              Subtotal
              <b className="text-gray-darkest">{subtotalPrice}</b>
            </p>
            {!isEmpty(cart?.shipping_addresses) && (
              <span className="flex mt-4 justify-between items-center w-full font-gotham text-sm">
                <p className="text-gray-dark">Frete</p>

                {!loading &&
                  (cart?.shipping_addresses[0]?.selected_shipping_method?.amount
                    ?.value === 0 ? (
                    <b className="flex items-center justify-center text-success-medium">
                      <CircleCheckedSVG className="text-success-medium w-[16px] h-[16px] mr-1" />
                      Grátis
                    </b>
                  ) : (
                    <b className="text-gray-darkest">
                      {formattedShippingPrice}
                    </b>
                  ))}
              </span>
            )}

            {!isEmpty(cart?.prices?.discounts) &&
              !loading &&
              cart?.prices?.discounts?.map((discount, index) => (
                <span
                  key={index}
                  className="flex mt-4 justify-between items-start w-full gap-4"
                >
                  <p className="text-sm text-gray-dark">{discount.label}</p>{' '}
                  <b className="text-success-medium flex items-center gap-[2px]">
                    <span>-</span>
                    {Intl.NumberFormat('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(discount.amount.value)}
                  </b>
                </span>
              ))}
          </div>

          {!isEmpty(cart?.applied_coupons) ? (
            cart?.applied_coupons?.map(coupon => (
              <div className="flex p-4 pb-4" key={coupon.code}>
                <div>
                  <span className="flex items-center text-success-medium font-bold text-sm">
                    <h1>{coupon.code}</h1>
                  </span>

                  <div className="pl-6 text-xs text-gray-medium">
                    {coupon.description}
                  </div>
                </div>

                <button onClick={() => handleRemoveCouponFromCart()}>
                  <TrashIcon className="text-gray-medium" />
                </button>
              </div>
            ))
          ) : (
            <form
              onSubmit={handleSubmit(handleCoupom)}
              className="flex justify-between items-center p-4 bg-gray-lightest"
            >
              <b className="text-sm text-primary-medium font-bold mr-2">
                Inserir <br /> cupom
              </b>

              <input
                placeholder="Código cupom"
                className="bg-white w-full border border-gray-light p-2 rounded-l"
                {...register('couponCode')}
              />

              <button
                type="submit"
                className="bg-primary-medium hover:bg-primary-dark border border-primary-medium rounded-r text-white h-full p-4"
              >
                <ArrowPoingRight />
              </button>
            </form>
          )}
        </div>

        <span
          className={
            'flex px-4 mt-4 justify-between items-center w-full font-gotham mb-4'
          }
        >
          <p className="text-sm text-gray-dark font-bold">Total</p>
          <b className="text-xl text-gray-darkest font-bold">{totalPrice}</b>
        </span>
      </div>
    </>
  );
};
