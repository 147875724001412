import { SingleProduct } from '@hooks';
import { APP_STORAGE_KEYS, Persistence } from '@utils';
import { atom } from 'jotai';

export interface Currency {
  currency: string;
  value: number;
}

export interface iProduct {
  id: string;
  sku: string;
  quantity: number;
  product: SingleProduct;
  errors: { message: string }[];
  configurable_options: {
    id: number;
    option_label: string;
    value_id: number;
    value_label: string;
  }[];
}

export interface iCart {
  id: string;
  items: iProduct[];
  is_recurrence: '0' | '1';
  prices: {
    subtotal_excluding_tax: Currency;
    applied_taxes: {
      amount: Currency;
      value: number;
    };
    discounts: {
      amount: Currency;
      label: string;
    }[];
    grand_total: Currency;
  };
  shipping_addresses: {
    customer_address_id: number;
    firstname: string;
    lastname: string;
    street: string[];
    city: string;
    region: {
      code: string;
      label: string;
      region_id: string;
    };
    country: {
      code: string;
      label: string;
    };
    postcode: string;
    company: string;
    telephone: string;
    available_shipping_methods: {
      description: string;
      amount: Currency;
      available: string;
      carrier_code: string;
      carrier_title: string;
      error_message: string;
      method_code: string;
      method_title: string;
      price_excl_tax: Currency;
      price_incl_tax: Currency;
    }[];
    selected_shipping_method: {
      amount: Currency;
      carrier_code: string;
      carrier_title: string;
      method_code: string;
      method_title: string;
    };
  }[];
  applied_gift_cards: {
    code: number;
    applied_balance: Currency;
  };
  applied_coupons: {
    code: string;
    description: string;
  }[];
  total_quantity: number;
}

export const cartIdAtom = atom(
  Persistence.getItem(APP_STORAGE_KEYS.cartId),
  (get, set, token: string | null) => {
    set(cartIdAtom, token);
  }
);

export const cartAtom = atom({} as iCart);

export const isSignatureAtom = atom(get => {
  const cart = get(cartAtom);
  return cart?.is_recurrence === '1';
});

export const hasAppliedCouponAtom = atom(get => {
  const cart = get(cartAtom);
  return cart?.applied_coupons?.length > 0;
});

export const selectedShippingMethodAtom = atom(get => {
  const cart = get(cartAtom);
  return cart?.shipping_addresses?.[0]?.selected_shipping_method;
});

// Debugging labels - These are used to identify the atoms in the Jotai devtools
cartIdAtom.debugLabel = 'cartIdAtom';
cartAtom.debugLabel = 'cartAtom';
isSignatureAtom.debugLabel = 'isSignatureAtom';
hasAppliedCouponAtom.debugLabel = 'hasAppliedCouponAtom';
selectedShippingMethodAtom.debugLabel = 'selectedShippingMethodAtom';
