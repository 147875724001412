import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import ReactPaginate, {
  dropFirstAndLast,
  ResponsivePaginationProps
} from 'react-responsive-pagination';
import ArrowLeft from '@assets/icons/arrow-left.svg';
import ArrowRight from '@assets/icons/arrow-right.svg';
import { usePathname } from 'next/navigation';

interface PaginationProps
  extends Omit<ResponsivePaginationProps, 'onPageChange'> {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  onPageChange: (page: number) => void;
  className?: string;
}

export const Pagination = ({
  page,
  setPage,
  onPageChange,
  className,
  ...props
}: PaginationProps) => {
  const pathname = usePathname();
  return (
    <ReactPaginate
      {...props}
      className={classNames(
        'flex text-gray-dark font-bold text-sm rounded overflow-hidden',
        className
      )}
      pageItemClassName="w-[40px] h-[40px] flex items-center justify-center  border border-collapse -ml-[1px] border-gray-dark"
      nextClassName={classNames(
        'flex items-center justify-center  w-[40px] lg:w-[72px] border border-l-0 rounded-r h-[40px] -ml-[0px]',
        {
          hidden: page === props.total
        }
      )}
      previousClassName={classNames(
        'flex items-center justify-center  w-[40px] lg:w-[72px] border border-r-0 rounded-l -ml-[0px] h-[40px]',
        {
          hidden: page === 1
        }
      )}
      activeItemClassName="bg-primary-medium text-white border-0"
      previousLabel={
        <>
          <span className="hidden lg:inline">Anterior</span>
          <span className="lg:hidden">
            <ArrowLeft width={8} />
          </span>
        </>
      }
      nextLabel={
        <>
          <span className="hidden lg:inline">Próximo</span>
          <span className="lg:hidden">
            <ArrowRight width={8} />
          </span>
        </>
      }
      total={props.total}
      narrowBehaviour={dropFirstAndLast}
      linkHref={pageIndex =>
        pageIndex === 1 ? pathname : `${pathname}?page=${pageIndex}`
      }
      onPageChange={selected => {
        setPage(selected);
        onPageChange(selected);
      }}
    />
  );
};
