import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import ArrowRightSVG from '@assets/icons/arrow-right.svg';

import classNames from 'classnames';

export interface AccordionForwardRef {
  open: () => void;
  close: () => void;
}

interface AccordionProps {
  checked?: boolean;
  label: string;
  value?: string;
  customIcon?: ReactNode;
  customClickHandler?: () => void;
  canBeEdited?: boolean;
  disabled?: boolean;
  children?: ReactNode | ReactNode[];
  hidden?: boolean;
  onOpen?(): void;
  id?: string;
  isProductPage?: boolean;
}

export type AccordionRef = React.RefObject<AccordionForwardRef>;

const AccordionComponent = (props: AccordionProps, ref: AccordionRef) => {
  const { onOpen, isProductPage = false } = props;
  const [collapsed, setCollapsed] = useState(isProductPage);
  const collapseControl = useRef(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setCollapsed(false);
    },
    close: () => {
      setCollapsed(true);
    }
  }));

  const clickHandler = useCallback(() => {
    setCollapsed(state => !state);
  }, []);

  useEffect(() => {
    if (!collapsed && !collapseControl.current) {
      onOpen?.();
      collapseControl.current = true;
    } else if (collapsed && collapseControl.current) {
      collapseControl.current = false;
    }
  }, [collapsed, onOpen]);

  return (
    <div className="w-full h-auto">
      <div
        id={props.id ?? 'content-label'}
        onClick={props?.customClickHandler || clickHandler}
        className={classNames(
          props?.disabled
            ? 'w-[inherit] h-[inherit] cursor-pointer flex justify-between px-2 lg:px-4 lg:py-2 border border-gray-light bg-gray-lightest'
            : isProductPage
              ? 'w-[inherit] h-[inherit] cursor-pointer flex justify-between px-2 lg:px-4 lg:py-2 border-t border-primary-light'
              : 'w-[inherit] h-[inherit] cursor-pointer flex justify-between px-2 lg:px-4 lg:py-2 border border-gray-light rounded-md',
          {
            hidden: props.hidden,
            'rounded-b-none': !collapsed
          }
        )}
      >
        <div className="flex flex-1 justify-between">
          <div className="flex justify-center items-center w-fit-content p-2">
            {props?.customIcon && props?.customIcon}

            <div className={props?.customIcon ? 'ml-2' : ''}>
              <span
                className={
                  isProductPage
                    ? 'font-sans font-bold normal text-base md:text-lg text-gray-darkest'
                    : 'font-sans font-bold normal text-base md:text-[18px] text-gray-dark'
                }
              >
                {props.label}
              </span>
            </div>

            {props.value && (
              <span className="hidden lg:flex justify-center ml-4 items-center w-fit-content font-sans font-normal normal text-sm text-gray-dark">
                {props.value}
              </span>
            )}
          </div>
          {isProductPage && (
            <div className="h-[inherit] flex items-center p-4">
              <ArrowRightSVG
                className={collapsed ? 'rotate-90' : 'rotate-[-90deg]'}
                color="#244ACF"
                height="16px"
                width="16px"
              />
            </div>
          )}
        </div>
        {props?.canBeEdited && (
          <div className="h-[inherit] flex items-center p-4">
            <p className="text-primary-medium underline font-bold">Alterar</p>
          </div>
        )}
      </div>

      <div
        className={classNames(
          isProductPage
            ? 'w-full'
            : 'w-full border border-t-0 border-gray-light rounded-b',
          collapsed ? 'hidden' : 'block'
        )}
      >
        {props.children}
      </div>
    </div>
  );
};

export const Accordion = forwardRef<AccordionForwardRef, AccordionProps>(
  AccordionComponent
);
