import SubscriptionMinibanner from '@components/common/TabBar/SubscriptionMinibanner';

type SignatureBlockProps = {
  className?: string;
};

export const SignatureBlock = ({ className }: SignatureBlockProps) => {
  return (
    <div className={className}>
      <SubscriptionMinibanner isCheckout />
    </div>
  );
};
