import ArrowRightSVG from '@assets/icons/arrow-right.svg';
import { SingleProduct, useCart } from '@hooks';
import { iProduct } from '@store/cart';
import { first } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { useMemo, useState } from 'react';
interface iOrderProducts {
  productsOnCart: iProduct[];
}

interface OrderItemProps {
  id: string;
  product: SingleProduct;
  configurableOptions?: {
    id: number;
    option_label: string;
    value_id: number;
    value_label: string;
  }[];
  quantity: number;
}

export const OrderProducts = ({ productsOnCart }: iOrderProducts) => {
  const { cartItemsQuantity } = useCart();
  const [showProducts, setShowProducts] = useState(true);
  const OrderItem = ({
    product,
    quantity,
    configurableOptions
  }: OrderItemProps) => {
    const productFiltered = useMemo(() => {
      const selectedFlavor = first(configurableOptions);

      if (product.__typename === 'ConfigurableProduct' && selectedFlavor) {
        const foundFlavor = product.variants.find(
          ({ product: variant }) =>
            variant.custom_flavor === selectedFlavor.value_id
        );

        if (foundFlavor) return foundFlavor?.product;

        return product;
      }

      return product;
    }, [configurableOptions, product]);

    const finalPrice = useMemo(() => {
      const tierPriceReached = product.price_tiers.find(
        tier => quantity >= tier.quantity
      );

      return Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL'
      }).format(
        tierPriceReached?.final_price?.value ||
          productFiltered?.price_range?.minimum_price?.final_price?.value ||
          0
      );
    }, [
      product.price_tiers,
      productFiltered?.price_range?.minimum_price?.final_price?.value,
      quantity
    ]);

    return (
      <div className="py-4 flex justify-between items-center [&:not(:last-child)]:border-b border-gray-light">
        <div className="flex w-full gap-2 ml-2">
          <Image
            src={productFiltered.small_image.url}
            width={64}
            height={64}
            alt={productFiltered.name}
            className="h-16 w-fit object-contain"
          />
          <div className="flex flex-col gap-[2px]">
            <h2 className="text-sm text-gray-darkest font-bold">
              {productFiltered.name}
            </h2>
            <span className="text-sm text-left text-gray-dark">{`${quantity} un.`}</span>
            <strong className="text-gray-darkest font-bold text-sm mt-4">
              {finalPrice}
            </strong>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mb-2">
      <div className="border border-gray-light rounded-md">
        <button
          className="border-b border-gray-light px-2 py-3 text-sm flex flex-1 w-full justify-between items-center"
          onClick={() => setShowProducts(!showProducts)}
        >
          <span>
            <b>{cartItemsQuantity}</b> itens
          </span>
          <ArrowRightSVG
            className={showProducts ? 'rotate-[-90deg]' : 'rotate-90'}
            color="#5044BF"
            height="12px"
            width="12px"
          />
        </button>
        <div className={!showProducts ? 'h-0 overflow-hidden' : 'h-auto'}>
          {productsOnCart?.map(cartItem => (
            <OrderItem
              id={cartItem.id}
              key={cartItem.id}
              product={cartItem.product}
              quantity={cartItem.quantity}
              configurableOptions={cartItem.configurable_options}
            />
          ))}
        </div>
        <span className="w-full mb-2 mt-2 flex justify-center items-center text-sm underline font-bold cursor-pointer text-primary-medium">
          <Link href="cart">Voltar para o carrinho</Link>
        </span>
      </div>
    </div>
  );
};
