import ArrowSVG from '@assets/icons/arrow-point-left.svg';
import classNames from 'classnames';
import { InputHTMLAttributes, useCallback, useMemo } from 'react';
import { FieldErrors } from 'react-hook-form';
import ReactInputMask from '@mona-health/react-input-mask';
import { ErrorMessage } from '../ErrorMessage';

interface iInput extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  labelClasses?: string;
  placeholder?: string;
  register: any;
  validations?: {
    required?: string;
    pattern?: { message: string; value: RegExp | string };
  };
  errors?: FieldErrors;
  value?: string;
  mask?: string;
  hasButton?: boolean;
  handleButton?: () => any;
  uppercase?: boolean;
}

export const Input = ({
  id = '',
  label = '',
  value = '',
  placeholder,
  errors,
  register,
  validations,
  handleButton,
  hasButton = false,
  mask = '',
  onBlur,
  onChange,
  uppercase,
  labelClasses,
  ...rest
}: iInput) => {
  const error = errors && errors?.[id];

  const errorMessage = useMemo(() => {
    return error?.message || '';
  }, [error]) as string;

  const toUpperCase = useCallback(
    event => {
      if (uppercase) {
        const {
          target: { value }
        } = event;
        event.target.value = '' + value.toUpperCase();
      }
    },
    [uppercase]
  );

  return (
    <div className="mt-2 w-full">
      <label
        className={classNames(
          'text-left text-sm font-bold',
          error ? 'text-error-medium' : 'text-gray-dark',
          labelClasses
        )}
      >
        {label}
      </label>

      <div className="flex">
        {mask ? (
          <ReactInputMask
            id={id}
            mask={mask}
            defaultValue={value}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            {...rest}
            {...register(id, validations)}
            className={classNames(
              'outline-none px-3 py-1 h-12 w-full rounded border focus:border-primary-light bg-white focus:bg-white text-gray-darkest',
              {
                'border-error-medium focus:active:border-error-medium':
                  errors?.[id],
                'border-gray-light': !errors?.[id]
              }
            )}
          />
        ) : (
          <input
            id={id}
            defaultValue={value}
            placeholder={placeholder}
            onInput={toUpperCase}
            className={classNames(
              'outline-none px-3 py-1 h-12 w-full rounded border focus:border-primary-light bg-white focus:bg-white text-gray-darkest',
              {
                'border-error-medium focus:active:border-error-medium':
                  errors?.[id],
                'border-gray-light': !errors?.[id]
              }
            )}
            onBlur={onBlur}
            onChange={onChange}
            {...rest}
            {...register(id, validations)}
          />
        )}

        {hasButton && (
          <button
            type="button"
            className="w-auto bg-[#009CDE] rounded mr-2"
            onClick={() => handleButton && handleButton()}
          >
            <div className="rotate-180 px-4">
              <ArrowSVG color="#ffffff" />
            </div>
          </button>
        )}
      </div>

      {errorMessage && <ErrorMessage error={errorMessage} />}
    </div>
  );
};
