import { APP_STORAGE_KEYS, Persistence } from '@utils';
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';

export interface iZipcodeContext {
  zipcode: string;
  handleSetZipcode(payload: string): void;
}

export const ZipcodeContext = createContext({} as iZipcodeContext);

export const ZipcodeProvider = memo(({ children }: { children: ReactNode }) => {
  const postcode = Persistence.getItem(APP_STORAGE_KEYS.postcode);
  const [zipcode, setZipcode] = useState('');

  const handleSetZipcode = useCallback((payload: string) => {
    setZipcode(payload);
  }, []);

  useEffect(() => {
    setZipcode(postcode);
  }, [postcode]);

  return (
    <ZipcodeContext.Provider value={{ zipcode, handleSetZipcode }}>
      {children}
    </ZipcodeContext.Provider>
  );
});

ZipcodeProvider.displayName = 'ZipcodeProvider';
