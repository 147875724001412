'use client';
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useStoreCode } from '@hooks';
import { APP_STORAGE_KEYS, Persistence } from '@utils';
import merge from 'deepmerge';
import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';

let storeCodePersisted = Persistence.getItem(APP_STORAGE_KEYS.storeViewCode);

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const cartId = Persistence.getItem(APP_STORAGE_KEYS.cartId);
    const token = Persistence.getItem(APP_STORAGE_KEYS.signinToken);

    graphQLErrors.forEach(({ extensions, message, locations, path }) => {
      if (extensions?.category === 'graphql-authorization' && cartId && token) {
        Persistence.removeItem(APP_STORAGE_KEYS.cartId);
        Persistence.removeItem(APP_STORAGE_KEYS.signinToken);
        history.go(0);
      }
      if (process.env.NODE_ENV !== 'production') {
        window.console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }
    });
  }
  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists.
  const token = Persistence.getItem(APP_STORAGE_KEYS.signinToken);
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_MAGENTO_BACKEND_BASE_URL,
  credentials: 'same-origin'
});

// Create a new store link to include store codes and currency in the request
const storeLink = setContext((_, { headers }) => {
  const storeCurrency =
    Persistence.getItem(APP_STORAGE_KEYS.storeViewCurrency) || null;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      store: storeCodePersisted || 'default',
      ...headers,
      ...(storeCurrency && { 'Content-Currency': storeCurrency })
    }
  };
});

export const createApolloClient = () =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink, authLink as any, storeLink, httpLink]),
    cache: new InMemoryCache()
  });

export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: any[], sourceArray: any[]) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s)))
      ]
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any
) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
};

export const useApollo = (pageProps: any) => {
  const { storeCode } = useStoreCode();

  useEffect(() => {
    storeCodePersisted = storeCode;
  }, [storeCode]);

  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
};
