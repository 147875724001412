/* eslint-disable no-console */
import { APP_STORAGE_KEYS, Persistence } from '@utils';
import { first, isEmpty } from 'lodash';
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import toast from 'react-hot-toast';

import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client';
import {
  AddSimpleProductToCartDocument,
  ApplyAppPromotionsDocument,
  ApplyCouponToCartDocument,
  CreateCartAfterSignInDocument,
  GetCartDetailsDocument,
  RemoveCouponFromCartDocument,
  RemoveItemForMiniCartDocument,
  SetShippingMethodDocument,
  ShippingCartPageDocument,
  UpdateItemQuantityDocument,
  useGetCustomerAddressesQuery,
  useGetCustomerCartQuery
} from '@generated/graphql';
import {
  clearCartDataFromCache,
  useStore,
  useStoreCode,
  useStoreSwitcher,
  useZipcode
} from '@hooks';
import {
  cartAtom,
  cartIdAtom,
  iCart,
  iProduct,
  isSignatureAtom
} from '@store/cart';
import { useAtom, useAtomValue } from 'jotai';
import { usePathname, useRouter } from 'next/navigation';
import logger from 'src/services/logger';

export const GET_CART_DETAIL_ID = gql`
  query GetCartDetails($cartId: String!) {
    cart(cart_id: $cartId) {
      id
    }
  }
`;

export interface iAddProduct {
  product: iProduct;
  quantity?: number;
  onCompleted: (data: any) => void;
  onError?: (error: any) => void;
}
export interface iUpdateProduct {
  quantity?: number;
  id?: boolean;
}
export interface iCartContext {
  cartId: string | null;
  cartToken(): Promise<string>;
  cart: iCart | undefined;
  isSignature: boolean;
  cartItemsQuantity: number;
  fetchCart(params?: any): Promise<any>;
  createCart(): Promise<any>;
  removeCart: () => Promise<void>;
  handleAddProductToCart(iAddProduct): Promise<void>;
  handleRemoveProductFromCart(itemId: string): Promise<void>;
  handleUpdateCartItemQuantity(iUpdateProduct): Promise<any>;
  handleAddCouponToCart(couponCode: string): void;
  /**
   * @param shouldFetchCart - default true - if false, will not fetch cart after removing coupon avoiding fetching cart multiple times
   */
  handleRemoveCouponFromCart(shouldFetchCart?: boolean): Promise<void>;
  loading: boolean;
  productPageAvailabilityError: boolean;
  setProductPageAvailabilityError(boolean): void;
  userAddressList: any;
  userAddressLoading: boolean;
  refetchUserAddresses(): Promise<any>;
  handleLoadShippingMethods(payload: {
    newCartId?: string;
    newZipCode?: string;
  }): Promise<any>;
  handleShippingMethodAfterUpdateCart(): Promise<any>;
  setShippingMethod(payload: {
    variables: {
      cartId: string;
      shippingMethod: {
        carrier_code: string;
        method_code: string;
      };
    };
  }): Promise<any>;
}

export const CartContext = createContext<iCartContext>({} as iCartContext);

export const CartProvider = memo(({ children }: { children: ReactNode }) => {
  const [createCartMutation] = useMutation(CreateCartAfterSignInDocument, {
    fetchPolicy: 'no-cache'
  });
  const [updateItemOnCart] = useMutation(UpdateItemQuantityDocument, {
    fetchPolicy: 'no-cache'
  });
  const [applyAppPromotions] = useMutation(ApplyAppPromotionsDocument, {
    fetchPolicy: 'no-cache'
  });
  const [setCouponToCart] = useMutation(ApplyCouponToCartDocument, {
    fetchPolicy: 'no-cache'
  });
  const [addProductToCart] = useMutation(AddSimpleProductToCartDocument, {
    fetchPolicy: 'no-cache'
  });
  const [removeItemFromCart] = useMutation(RemoveItemForMiniCartDocument, {
    fetchPolicy: 'no-cache'
  });
  const [setShippingMethod] = useMutation(SetShippingMethodDocument, {
    fetchPolicy: 'no-cache'
  });
  const [deleteCouponFromCart] = useMutation(RemoveCouponFromCartDocument, {
    fetchPolicy: 'no-cache'
  });
  const [setDefaultShippingMethod] = useMutation(ShippingCartPageDocument, {
    fetchPolicy: 'no-cache'
  });

  let cartIdFromLS = Persistence.getItem(APP_STORAGE_KEYS.cartId) as string;
  const token = Persistence.getItem(APP_STORAGE_KEYS.signinToken) as string;

  const { refetch: getCartDetails } = useQuery(GET_CART_DETAIL_ID, {
    variables: { cartId: cartIdFromLS },
    skip: !cartIdFromLS
  });

  const getCurrentCartId = useCallback(async () => {
    if (cartIdFromLS) {
      const { data } = await getCartDetails();
      return data?.cart?.id;
    }
    throw new Error('cart_id should not be empty while fetching cart');
  }, [cartIdFromLS, getCartDetails]);

  const apolloClient = useApolloClient();
  const pathname = usePathname();
  const { zipcode } = useZipcode();
  const { availableStores } = useStore();
  const { handleSetStore } = useStoreCode();
  const { handleSwitchStoreByPostcode } = useStoreSwitcher();
  const [cart, setCart] = useAtom<iCart>(cartAtom);
  const [cartId, setCartId] = useAtom<string | null>(cartIdAtom);
  const isSignature = useAtomValue(isSignatureAtom);
  const [productPageAvailabilityError, setProductPageAvailabilityError] =
    useState<boolean>(false);

  const {
    data: userAddressData,
    loading: userAddressLoading,
    refetch: refetchUserAddresses
  } = useGetCustomerAddressesQuery({ skip: !cartIdFromLS || !token });

  const router = useRouter();
  const isSignedIn = Persistence.getItem(APP_STORAGE_KEYS.signinToken);
  const { error: customerCartError } = useGetCustomerCartQuery({
    skip: !isSignedIn
  });

  const userAddressList = useMemo(() => {
    if (userAddressData?.customer.addresses) {
      const defaultShipmentAddress = userAddressData.customer.addresses.find(
        ({ default_shipping }) => default_shipping === true
      );

      if (defaultShipmentAddress)
        return [
          defaultShipmentAddress,
          ...userAddressData.customer.addresses.filter(
            ({ default_shipping }) => default_shipping !== true
          )
        ];
    }

    return userAddressData?.customer.addresses;
  }, [userAddressData]);

  const [fetchCart, { loading }] = useLazyQuery(GetCartDetailsDocument, {
    variables: { cartId },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: cartData => {
      if (cartData?.cart) {
        const cartWithoutNullItems = {
          ...cartData?.cart,
          items: cartData?.cart?.items?.filter(item => item)
        };
        setCart(cartWithoutNullItems as iCart);
      } else {
        setCart({} as iCart);
      }
    },
    onError: error => {
      logger.error(error);
      setCart({} as iCart);
    }
  });

  const createCart = useCallback(async () => {
    try {
      const {
        data: { cartId: createdCartId }
      } = await createCartMutation();

      if (createdCartId) {
        Persistence.setItem(APP_STORAGE_KEYS.cartId, createdCartId);
        setCartId(createdCartId);

        return createdCartId;
      } else {
        setCartId(null);
        throw new Error('Um erro aconteceu tentando criar seu carrinho novo');
      }
    } catch (err) {
      toast.error(err.message);
    }
  }, [createCartMutation, setCartId]);

  const cartToken = useCallback(async () => {
    let token = '';
    try {
      token = await getCurrentCartId();
    } catch {
      token = await createCart();
    }
    await Persistence.setItem(APP_STORAGE_KEYS.cartId, token);
    setCartId(token);
    return token;
  }, [createCart, getCurrentCartId, setCartId]);

  const errorToAddProductToCart = useCallback(
    async ({ error, callback }) => {
      const fallbackStoreCode = Persistence.getItem(
        APP_STORAGE_KEYS.storeVendorFallbackCode
      );

      if (fallbackStoreCode) {
        const usedFallback = await handleSwitchStoreByPostcode(
          zipcode,
          true,
          callback
        );

        if (usedFallback) {
          setProductPageAvailabilityError(false);
        }
      } else {
        setProductPageAvailabilityError(true);

        const isAuthError = error?.message?.includes?.(
          'O usuário atual não pode realizar operações no carrinho'
        );
        if (!pathname.includes('/p') && !isAuthError) {
          toast.error(error?.message);
        }
      }
    },
    [handleSwitchStoreByPostcode, pathname, zipcode]
  );

  const handleAppPromotions = useCallback(async () => {
    try {
      await applyAppPromotions({
        variables: {
          cartId: cartIdFromLS,
          value: '0'
        }
      });
    } catch (e) {
      logger.error(e);
    }
  }, [applyAppPromotions, cartIdFromLS]);

  useEffect(() => {
    if (cartIdFromLS) {
      handleAppPromotions();
    }
  }, [cartIdFromLS, handleAppPromotions]);

  const handleAddProductToCart = useCallback(
    async ({ quantity = 1, product, onCompleted, onError }: iAddProduct) => {
      const cartIdCreated = await cartToken();

      if (cartIdCreated) {
        const fallbackStoreCode = Persistence.getItem(
          APP_STORAGE_KEYS.storeVendorFallbackCode
        );

        const { errors: errorsSimple } = await addProductToCart({
          variables: {
            cartId: cartIdCreated,
            quantity: quantity,
            sku: product?.sku
          },
          errorPolicy: 'all',
          onCompleted: data =>
            onCompleted({ data, createdCartId: cartIdCreated }) as any
        });

        if (errorsSimple && errorsSimple.length > 0) {
          await errorToAddProductToCart({
            error: errorsSimple[0],
            callback: async () => {
              if (fallbackStoreCode) {
                const { errors: errorsFallback } = await addProductToCart({
                  variables: {
                    cartId: cartIdCreated,
                    quantity: quantity,
                    sku: product?.sku
                  },
                  errorPolicy: 'all',
                  context: { headers: { store: fallbackStoreCode } },
                  onCompleted: data =>
                    onCompleted({ data, createdCartId: cartIdCreated }) as any
                });
                if (errorsFallback && errorsFallback.length > 0) {
                  setProductPageAvailabilityError(true);
                  if (onError) {
                    onError(errorsFallback[0]);
                  }
                }
              }
            }
          });
        }
      }
    },
    [addProductToCart, cartToken, errorToAddProductToCart]
  );

  const handleShippingMethodAfterUpdateCart: iCartContext['handleShippingMethodAfterUpdateCart'] =
    useCallback(async () => {
      const shippingAddressOnCart = first(cart?.shipping_addresses);
      const availableShippingMethod = first(
        shippingAddressOnCart?.available_shipping_methods
      );
      if (shippingAddressOnCart?.selected_shipping_method) return;

      if (isEmpty(availableShippingMethod)) return;

      await setShippingMethod({
        variables: {
          cartId,
          shippingMethod: {
            carrier_code: availableShippingMethod?.carrier_code,
            method_code: availableShippingMethod?.method_code
          }
        },
        onCompleted(data) {
          const cartResponse = data?.setShippingMethodsOnCart?.cart;
          if (cartResponse) {
            const shippingAddressOnCart = cartResponse?.shipping_addresses;
            setCart({
              ...cart,
              shipping_addresses: shippingAddressOnCart,
              prices: cartResponse?.prices
            });
          }
        }
      });
    }, [cartId, setShippingMethod, setCart, cart]);

  const handleRemoveProductFromCart: iCartContext['handleRemoveProductFromCart'] =
    useCallback(
      async itemId => {
        await removeItemFromCart({
          variables: {
            cartId,
            itemId
          },
          errorPolicy: 'all',
          onCompleted: async data => {
            const cartData = data?.removeItemFromCart?.cart;
            const { shipping_addresses } = cartData || {};
            const updatedCart: iCart = {
              ...cart,
              items: cart.items?.filter(item => item.id !== itemId),
              prices: cartData?.prices,
              shipping_addresses: [
                ...(cart?.shipping_addresses.map(address => ({
                  ...address,
                  selected_shipping_method:
                    shipping_addresses[0]?.selected_shipping_method,
                  available_shipping_methods:
                    shipping_addresses[0]?.available_shipping_methods
                })) || [])
              ]
            };
            setCart(updatedCart);
          }
        });
      },
      [removeItemFromCart, cart, cartId, setCart]
    );

  const handleUpdateCartItemQuantity = useCallback(
    async ({ id, quantity }: iUpdateProduct) => {
      if (cartId) {
        const fallbackStoreCode = Persistence.getItem(
          APP_STORAGE_KEYS.storeVendorFallbackCode
        );

        const { errors } = await updateItemOnCart({
          variables: {
            cartId,
            itemId: id,
            quantity
          },
          errorPolicy: 'all',
          onCompleted: async data => {
            const cartData = data?.updateCartItems;
            if (cartData?.cart) {
              const {
                total_quantity,
                is_recurrence,
                items,
                prices,
                shipping_addresses
              } = (cartData?.cart || {}) as iCart;

              const updatedCart: iCart = {
                ...cart,
                is_recurrence,
                total_quantity,
                items: cart.items?.map(item => ({
                  ...item,
                  quantity: items.find(({ id }) => id === item.id)?.quantity
                })),
                shipping_addresses: [
                  ...(cart?.shipping_addresses.map(address => ({
                    ...address,
                    selected_shipping_method:
                      shipping_addresses[0]?.selected_shipping_method,
                    available_shipping_methods:
                      shipping_addresses[0]?.available_shipping_methods
                  })) || [])
                ],
                prices
              };
              setCart(updatedCart);
            }
          }
        });

        if (errors && errors.length > 0) {
          await errorToAddProductToCart({
            error: errors[0],
            callback: async () =>
              await updateItemOnCart({
                variables: {
                  cartId,
                  itemId: id,
                  quantity
                },
                context: { headers: { store: fallbackStoreCode } },
                onCompleted: async () => await fetchCart()
              })
          });
        }
      }
    },
    [
      errorToAddProductToCart,
      fetchCart,
      updateItemOnCart,
      cartId,
      setCart,
      cart
    ]
  );

  const handleAddCouponToCart = useCallback(
    async (couponCode: string) => {
      const { errors } = await setCouponToCart({
        variables: {
          cartId,
          couponCode
        },
        errorPolicy: 'all'
      });
      if (errors) {
        const err = errors.find(
          err =>
            err?.message !== 'A quantidade selecionada não está disponível' &&
            err?.message !== 'The requested qty is not available' &&
            err?.message !== 'Alguns dos produtos estão fora de estoque.'
        );
        if (err) toast.error(err?.message);
      }
      await fetchCart();
    },
    [setCouponToCart, fetchCart, cartId]
  );

  const handleRemoveCouponFromCart = useCallback(
    async (shouldFetchCart = true) => {
      const { errors } = await deleteCouponFromCart({
        variables: {
          cartId
        },
        errorPolicy: 'all'
      });
      if (errors) {
        const err = errors.find(
          err =>
            err?.message !== 'A quantidade selecionada não está disponível' &&
            err?.message !== 'The requested qty is not available' &&
            err?.message !== 'Alguns dos produtos estão fora de estoque.'
        );
        if (err) toast.error(err?.message);
      }
      if (shouldFetchCart) {
        await fetchCart();
      }
      return;
    },
    [deleteCouponFromCart, fetchCart, cartId]
  );

  const handleLoadShippingMethods = useCallback<
    iCartContext['handleLoadShippingMethods']
  >(
    async ({ newCartId, newZipCode = zipcode }) => {
      if (newZipCode) {
        await setDefaultShippingMethod({
          variables: {
            cartId: newCartId || cartId,
            postCode: newZipCode
          },
          onCompleted: async () => {
            const { data: defaultShippingData } = await fetchCart();

            const shippingAddressOnCart = first(
              defaultShippingData?.cart?.shipping_addresses
            ) as any;

            const availableShippingMethod = first(
              shippingAddressOnCart?.available_shipping_methods
            ) as any;

            if (!isEmpty(availableShippingMethod)) {
              await setShippingMethod({
                variables: {
                  cartId: newCartId || cartId,
                  shippingMethod: {
                    carrier_code: availableShippingMethod?.carrier_code,
                    method_code: availableShippingMethod?.method_code
                  }
                }
              });
              await fetchCart();
            }
          }
        });
      }
    },
    [fetchCart, setDefaultShippingMethod, setShippingMethod, zipcode, cartId]
  );

  const removeCart = useCallback(async () => {
    Persistence.removeItem(APP_STORAGE_KEYS.cartId);
    setCartId(null);
    await clearCartDataFromCache(apolloClient);
  }, [apolloClient, setCartId]);

  const cartItemsQuantity = useMemo(() => {
    return cart?.items?.reduce((prev, current) => {
      return current?.quantity + prev;
    }, 0);
  }, [cart]);

  const foundErrorInCart = useMemo(() => {
    const productWithError = cart?.items?.find(
      product => product?.errors?.length > 0 && product
    );

    if (productWithError?.errors) {
      const [cartError] = productWithError.errors;

      return cartError;
    }

    return null;
  }, [cart?.items]);

  useEffect(() => {
    const handleError = async err => {
      const fallbackStoreCode = Persistence.getItem(
        APP_STORAGE_KEYS.storeVendorFallbackCode
      );

      if (
        (err?.message === 'A quantidade selecionada não está disponível' ||
          err?.message === 'The requested qty is not available' ||
          err?.message === 'Alguns dos produtos estão fora de estoque.') &&
        fallbackStoreCode
      ) {
        let fallbackStore;
        availableStores.forEach(store => {
          if (store.code === fallbackStoreCode) fallbackStore = store;
        });

        if (!isEmpty(fallbackStore)) {
          handleSetStore(fallbackStore?.code);
          Persistence.setItem(
            APP_STORAGE_KEYS.storeViewCurrency,
            fallbackStore.currency
          );
          Persistence.setItem(
            APP_STORAGE_KEYS.storeViewSecureBaseMediaUrl,
            fallbackStore.secure_base_media_url
          );

          Persistence.removeItem(APP_STORAGE_KEYS.storeVendorFallbackCode);
          await fetchCart();
        }
      }
    };

    if (foundErrorInCart) handleError(foundErrorInCart);
  }, [availableStores, foundErrorInCart, fetchCart, handleSetStore]);

  /**
   * When useCart is called, if it gets any authorization error
   * clear cart and local storage and redirects to sign-in
   */
  useEffect(() => {
    const cleanAndRedirectToSignIn = async () => {
      await removeCart();

      Persistence.clearUserItems();
      router.push(`/sign-in?redirect=${pathname}`);
    };

    if (
      isSignedIn &&
      customerCartError?.graphQLErrors?.[0]?.extensions?.category ===
        'graphql-authorization'
    ) {
      cleanAndRedirectToSignIn();
    }
  }, [
    customerCartError?.graphQLErrors,
    pathname,
    isSignedIn,
    removeCart,
    router
  ]);

  return (
    <CartContext.Provider
      value={{
        cart,
        cartId: cartIdFromLS,
        cartToken,
        cartItemsQuantity,
        handleAddProductToCart,
        handleRemoveProductFromCart,
        handleUpdateCartItemQuantity,
        handleAddCouponToCart,
        handleRemoveCouponFromCart,
        createCart,
        removeCart,
        loading,
        fetchCart,
        productPageAvailabilityError,
        setProductPageAvailabilityError,
        userAddressList,
        userAddressLoading,
        refetchUserAddresses,
        setShippingMethod,
        handleLoadShippingMethods,
        handleShippingMethodAfterUpdateCart,
        isSignature
      }}
    >
      {children}
    </CartContext.Provider>
  );
});

CartProvider.displayName = 'CartProvider';
