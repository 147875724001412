import { isEmpty } from 'lodash';
import { toast } from 'react-hot-toast';
import { APP_STORAGE_KEYS, Persistence } from './persistence';

export const possibleErrorMessages = [
  'A quantidade selecionada não está disponível',
  'The requested qty is not available',
  'Alguns dos produtos estão fora de estoque.'
];

export const handleStockError = async (
  error,
  availableStores,
  handleSetStore
) => {
  const fallbackStoreCode = Persistence.getItem(
    APP_STORAGE_KEYS.storeVendorFallbackCode
  );
  const isOutOfStock = possibleErrorMessages
    .map(errorMessage => error.message.includes(errorMessage))
    .some(errorMessageResult => errorMessageResult === true);

  if (isOutOfStock) {
    if (fallbackStoreCode) {
      let fallbackStore;
      await availableStores.forEach(store => {
        if (store.code === fallbackStoreCode) fallbackStore = store;
      });

      if (!isEmpty(fallbackStore)) {
        handleSetStore(fallbackStore?.code);
        Persistence.setItem(
          APP_STORAGE_KEYS.storeViewCurrency,
          fallbackStore.currency
        );
        Persistence.setItem(
          APP_STORAGE_KEYS.storeViewSecureBaseMediaUrl,
          fallbackStore.secure_base_media_url
        );

        Persistence.removeItem(APP_STORAGE_KEYS.storeVendorFallbackCode);
      }
    } else {
      toast.error('Quantidade para produto sem estoque.');
    }

    return true;
  } else {
    return false;
  }
};
