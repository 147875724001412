import { APP_STORAGE_KEYS, Persistence } from '@utils';
import { useMemo } from 'react';

export const useAdminUser = () => {
  const admin_user = Persistence.getItem(APP_STORAGE_KEYS.adminUser);

  const isAdmin = useMemo(() => {
    return !!admin_user?.adminId;
  }, [admin_user]);

  return {
    adminUser: admin_user,
    isAdmin
  };
};
