import { gql, useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useStoreCode } from './useStoreCode';

const GET_CURRENT_STORE_CONFIG_DATA = gql`
  query getCurrentStoreConfigData {
    storeConfig {
      code
      id
      locale
      vendor_postcode_ranges
      vendor_is_in_transition
      freeshipping_custom_value
      is_default_store
      root_category_id
      skin_black_friday
      configurable_thumbnail_source
      __typename
    }
  }
`;

const GET_AVAILABLE_STORES_CONFIG_DATA = gql`
  query getAvailableStoresData {
    availableStores {
      category_url_suffix
      code
      default_display_currency_code
      id
      locale
      product_url_suffix
      secure_base_media_url
      store_group_code
      store_group_name
      store_name
      store_sort_order
      vendor_postcode_ranges
      vendor_is_in_transition
      freeshipping_custom_value
      vendor_priority
      is_default_store
      root_category_id
      configurable_thumbnail_source
      __typename
    }
  }
`;

const mapAvailableOptions = (config, stores) => {
  const { code: configCode } = config;

  return stores.reduce((map, store) => {
    const {
      category_url_suffix,
      code,
      default_display_currency_code: currency,
      locale,
      product_url_suffix,
      secure_base_media_url,
      store_group_code: storeGroupCode,
      store_group_name: storeGroupName,
      store_name: storeName,
      store_sort_order: sortOrder,
      vendor_postcode_ranges,
      vendor_is_in_transition,
      freeshipping_custom_value,
      vendor_priority,
      is_default_store,
      root_category_id,
      configurable_thumbnail_source
    } = store;

    const isCurrent = code === configCode;

    const option = {
      category_url_suffix,
      code,
      currency,
      isCurrent,
      locale,
      product_url_suffix,
      secure_base_media_url,
      sortOrder,
      storeGroupCode,
      storeGroupName,
      freeshipping_custom_value,
      storeName,
      vendor_postcode_ranges: vendor_postcode_ranges.split(',').map(range =>
        range
          .trim()
          .split('-')
          .map(entry => Number.parseInt(entry))
      ),
      vendor_is_in_transition,
      vendor_priority,
      is_default_store,
      root_category_id,
      configurable_thumbnail_source
    };

    return map.set(code, option);
  }, new Map());
};

export const useStore = () => {
  const { storeCode } = useStoreCode();

  const { data: currentStoreConfigData, refetch: refetchCurrentStore } =
    useQuery(GET_CURRENT_STORE_CONFIG_DATA, {
      fetchPolicy: 'no-cache'
    });

  const { data: availableStoresData, refetch: refetchAvailableStores } =
    useQuery(GET_AVAILABLE_STORES_CONFIG_DATA, {
      fetchPolicy: 'no-cache'
    });

  const isDefaultStore = useMemo(() => {
    if (currentStoreConfigData) {
      return currentStoreConfigData.storeConfig.is_default_store;
    }
  }, [currentStoreConfigData]);

  const isVendorInTransition = useMemo(() => {
    if (currentStoreConfigData) {
      return currentStoreConfigData.storeConfig.vendor_is_in_transition;
    }
  }, [currentStoreConfigData]);

  // availableStores => mapped options or empty map if undefined.
  const availableStores: Map<any, any> = useMemo(() => {
    return (
      (currentStoreConfigData &&
        availableStoresData &&
        mapAvailableOptions(
          currentStoreConfigData.storeConfig,
          availableStoresData.availableStores
        )) ||
      new Map()
    );
  }, [currentStoreConfigData, availableStoresData]);

  const currentStore = useMemo(() => {
    if (currentStoreConfigData) {
      return {
        ...currentStoreConfigData.storeConfig,
        vendor_postcode_ranges:
          currentStoreConfigData.storeConfig.vendor_postcode_ranges
            .split(',')
            .map(range =>
              range
                .trim()
                .split('-')
                .map(entry => Number.parseInt(entry))
            )
      };
    }
  }, [currentStoreConfigData]);

  useEffect(() => {
    refetchCurrentStore();
  }, [currentStore, refetchCurrentStore, storeCode]);

  return {
    currentStore,
    isDefaultStore,
    availableStores,
    refetchCurrentStore,
    refetchAvailableStores,
    isVendorInTransition
  };
};
