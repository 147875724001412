import { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { useCheckout } from 'src/hooks/useCheckout';

export const GenericForm = ({
  handlePlaceOrder,
  children
}: {
  handlePlaceOrder(): Promise<void>;
  children: ReactNode;
}) => {
  const { handleSubmit } = useForm();
  const { handleSubmitPaymentMethodForm } = useCheckout();

  return (
    <form
      className="w-full h-full py-4 px-0"
      onSubmit={handleSubmit(async () => {
        await handleSubmitPaymentMethodForm();
        await handlePlaceOrder();
      })}
    >
      {children}
    </form>
  );
};
